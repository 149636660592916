import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { ROUTE_LOGS_EXPORT_DATE_FORMAT, ROUTE_LOGS_FIELDS_EXPORT } from '../constants/RoutingLogConstants';

type RouteLogsDownload = {
	start_date: string | null;
	end_date: string | null;
	fields: string[];
};

const initialState: RouteLogsDownload = {
	start_date: dayjs().subtract(30, 'day').format(ROUTE_LOGS_EXPORT_DATE_FORMAT),
	end_date: dayjs().format(ROUTE_LOGS_EXPORT_DATE_FORMAT),
	fields: ROUTE_LOGS_FIELDS_EXPORT,
};

type PayloadDate = {
	name: 'start_date' | 'end_date';
	value: string | null;
};

export const routeLogsDownloadSlice = createSlice({
	name: 'routeLogsDownload',
	initialState,
	reducers: {
		setDate: (state, action: PayloadAction<PayloadDate>) => {
			state[action.payload.name] = action.payload.value;
		},
		addField: (state, action: PayloadAction<string>) => {
			state.fields.push(action.payload);
		},
		removeField: (state, action: PayloadAction<string>) => {
			const index = state.fields.indexOf(action.payload);
			if (index > -1) {
				// only splice array when item is found
				state.fields.splice(index, 1); // 2nd parameter means remove one item only
			}
		},
	},
});

export const { setDate, addField, removeField } = routeLogsDownloadSlice.actions;

export default routeLogsDownloadSlice.reducer;
