import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router';
import { Router } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LicenseInfo } from '@mui/x-license';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import isObject from 'lodash-es/isObject';
import { enqueueSnackbar } from 'notistack';
import NProgress from 'nprogress';

import './wdyr';

import { environment } from '../environments/environment';

import AuthRouting from './auth/Routing';
import { logout } from './auth/services/authSlice';
import { initAmplitude } from './integrations/amplitude/amplitude';
import { initDataDog } from './integrations/datadog/datadog';
import ConfirmationModalContextProvider from './utils/components/ConfirmModal/modalConfirmationContext';
import RingLeadLoadable from './utils/components/RingLeadLoadable/RingLeadLoadable';
import RingLeadSnackbarProvider from './utils/components/Snackbar/RingLeadSnackbarProvider';
import Routing from './utils/routes/Routes';
import store, { history } from './utils/stores/store';
import theme from './utils/Theme';

const Root = RingLeadLoadable({
	loader: () => import('./Root'),
});

const NotFoundPage = RingLeadLoadable({
	loader: () => import('./utils/components/NotFoundPage'),
});

let requestsCounter = 0;

axios.defaults.baseURL = environment.apiBaseUrl;

axios.interceptors.request.use(config => {
	requestsCounter += 1;
	NProgress.start();
	return config;
});

const calculatePercentage = (loaded, total) => Math.floor(loaded * 1.0) / total;

const update = e => NProgress.inc(calculatePercentage(e.loaded, e.total));
axios.defaults.onDownloadProgress = update;
axios.defaults.onUploadProgress = update;

const responseFunc = response => {
	requestsCounter -= 1;
	if (requestsCounter === 0) {
		NProgress.done();
	}

	return response;
};

const errorFunc = error => {
	requestsCounter -= 1;
	if (requestsCounter === 0) {
		NProgress.done();
	}

	const response = error.response || {};
	response.config = response.config || {};

	// !response.status - typical situation in the oldest browser with cancel flow
	if (axios.isCancel(error) || !response.status) {
		return Promise.reject(error);
	}

	switch (response.status) {
		case 0: // magic error if abruptly cancelling request
		case 400: // typically validation errors
			break;
		case 403: // typically forbidden errors
			enqueueSnackbar({
				key: '403',
				message: 'You do not have permission to perform this action.',
				variant: 'error',
			});
			break;
		case 502: // Bad Gateway
			enqueueSnackbar({
				key: '502',
				message: 'Sorry, but service is unavailable. Please, try later.',
				variant: 'error',
			});
			break;
		case 401:
			store.dispatch(logout());
			break;
		default:
			let err = `URL: ${response.config.url || window.location.pathname}. Status: ${
				response.status
			}. Status text: ${response.statusText}`;

			if (response.data && isObject(response.data)) {
				err += `. Response: ${JSON.stringify(response.data)}`;
			}

			if (response.error && isObject(response.error)) {
				err += `. Error: ${JSON.stringify(response.error)}`;
			}

			enqueueSnackbar({
				key: err,
				message: err,
				variant: 'error',
			});
			break;
	}

	return Promise.reject(error);
};

axios.interceptors.response.use(responseFunc, errorFunc);

LicenseInfo.setLicenseKey(
	'391581399dbc393e3680fd64f51d0abdTz03MzcyNixFPTE3MjUwMjc4MjQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

if (environment.sentryDsn) {
	Sentry.init({
		dsn: environment.sentryDsn,
		release: process.env.NX_PUBLIC_BUILD || '-',
		ignoreErrors: [
			'fb_xd_fragment',
			/AdaEmbedError:.*/,
			/AxiosError:.*/,
			/CanceledError:.*/,
			/NotSupportedError.*/,
			'ResizeObserver loop limit exceeded',
		],
		denyUrls: [/static\.ada\.support/i, /extensions\//i, /^chrome:\/\//i],
	});
}

initAmplitude();
initDataDog();

history.listen(() => {
	window.scrollTo(0, 0);
});

ReactDOM.render(
	<Provider store={store}>
		<Router history={history}>
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={theme}>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<RingLeadSnackbarProvider>
							<ConfirmationModalContextProvider>
								<CssBaseline />
								{window.location.origin !== 'https://translate.googleusercontent.com' && (
									<Switch>
										{AuthRouting}

										<Root>
											<Switch>{Routing}</Switch>
										</Root>

										<Route path="*" component={NotFoundPage} />
									</Switch>
								)}
							</ConfirmationModalContextProvider>
						</RingLeadSnackbarProvider>
					</LocalizationProvider>
				</ThemeProvider>
			</StyledEngineProvider>
		</Router>
	</Provider>,
	document.getElementById('app')
);
