import concat from 'lodash-es/concat';
import filter from 'lodash-es/filter';
import find from 'lodash-es/find';
import includes from 'lodash-es/includes';
import map from 'lodash-es/map';
import pickBy from 'lodash-es/pickBy';
import reduce from 'lodash-es/reduce';
import reject from 'lodash-es/reject';
import { LOCATION_CHANGE } from 'redux-first-history';

import {
	RoutingWorkFlowScenarioActionConstants,
	RoutingWorkFlowScenarioConstants,
	RoutingWorkFlowScenarioCriteriaConstants,
} from '../Constants';

const initialState = {
	scenarios: [],
	fetching: true,
	isActionCreateRequest: false,
	scenarioErrors: {},
	actionErrors: {},
	criteriaErrors: {},
};

const isRuleIdOrParentId = (rule, id) => rule.id === id || rule.parent_rule === id;

// eslint-disable-next-line sonarjs/cognitive-complexity
export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			if (
				includes(action.payload.location.pathname, '/routing/workflows/') &&
				action.payload.location.pathname !== '/routing/workflows/' &&
				!includes(action.payload.location.pathname, 'auth')
			) {
				return state;
			}

			return initialState;

		case RoutingWorkFlowScenarioConstants.WORKFLOW_SCENARIO_LIST_REQUEST:
			return { ...state, fetching: true };

		case RoutingWorkFlowScenarioConstants.WORKFLOW_SCENARIO_LIST_SUCCESS:
			return { ...state, scenarios: action.data, fetching: false };

		case RoutingWorkFlowScenarioConstants.WORKFLOW_SCENARIO_LIST_FAIL:
			return { ...state, scenarios: [], fetching: false };

		case RoutingWorkFlowScenarioConstants.WORKFLOW_SCENARIO_CREATE_SUCCESS:
		case RoutingWorkFlowScenarioConstants.WORKFLOW_SCENARIO_COPY_SUCCESS:
			return {
				...state,
				scenarios: concat([], state.scenarios, [{ ...action.data, __isNew__: true }]),
			};

		case RoutingWorkFlowScenarioConstants.WORKFLOW_SCENARIO_CREATE_FAIL:
		case RoutingWorkFlowScenarioConstants.WORKFLOW_SCENARIO_COPY_FAIL:
			return state;

		case RoutingWorkFlowScenarioConstants.WORKFLOW_SCENARIO_UPDATE_SUCCESS:
			return {
				...state,
				scenarios: map(state.scenarios, workflowRule => {
					let actionWorkflowRule = find(action.data, wR =>
						action.data.length > 1 ? wR.id === workflowRule.id : isRuleIdOrParentId(workflowRule, wR.id)
					);

					// sync child rule
					if (actionWorkflowRule && workflowRule.parent_rule === actionWorkflowRule.id) {
						actionWorkflowRule = { ...workflowRule, name: actionWorkflowRule.name };
					}

					return actionWorkflowRule ? { ...workflowRule, ...actionWorkflowRule } : workflowRule;
				}),
				scenarioErrors: pickBy(state.scenarioErrors, (_v, k) =>
					includes(
						map(action.data, r => r.id.toString()),
						k
					)
				),
			};

		case RoutingWorkFlowScenarioConstants.WORKFLOW_SCENARIO_UPDATE_FAIL:
			return {
				...state,
				scenarios: map(state.scenarios, workflowRule => {
					const updatedWorkflowRule = find(action.data, ['id', workflowRule.id]);

					return updatedWorkflowRule ? { ...workflowRule, ...updatedWorkflowRule } : workflowRule;
				}),
				scenarioErrors: {
					...state.scenarioErrors,
					...reduce(action.data, (result, r, index) => ({ ...result, [r.id]: action.error[index] }), {}),
				},
			};

		case RoutingWorkFlowScenarioConstants.WORKFLOW_SCENARIO_DELETE_SUCCESS:
			return {
				...state,
				scenarios: reject(state.scenarios, workflowRule =>
					action.unShare
						? workflowRule.parent_rule === action.scenarioId
						: isRuleIdOrParentId(workflowRule, action.scenarioId)
				),
			};

		case RoutingWorkFlowScenarioConstants.WORKFLOW_SCENARIO_DELETE_FAIL:
			return state;

		case RoutingWorkFlowScenarioActionConstants.WORKFLOW_SCENARIO_ACTION_CREATE_SUCCESS:
			return {
				...state,
				isActionCreateRequest: false,
				scenarios: map(state.scenarios, workflowRule =>
					isRuleIdOrParentId(workflowRule, action.data.routing_rule)
						? {
								...workflowRule,
								workflow_rule_actions: concat(
									[],
									action.scenarioAction.tmpId
										? reject(workflowRule.workflow_rule_actions, [
												'tmpId',
												action.scenarioAction.tmpId,
											])
										: workflowRule.workflow_rule_actions,
									[action.data]
								),
							}
						: workflowRule
				),
				actionErrors: pickBy(state.actionErrors, (_v, k) => action.scenarioAction.tmpId !== k),
			};

		case RoutingWorkFlowScenarioActionConstants.WORKFLOW_SCENARIO_ACTION_CREATE_FAIL:
			return {
				...state,
				scenarios: map(state.scenarios, workflowRule =>
					isRuleIdOrParentId(workflowRule, action.scenarioAction.routing_rule)
						? {
								...workflowRule,
								workflow_rule_actions: concat(
									[],
									reject(workflowRule.workflow_rule_actions, ['tmpId', action.scenarioAction.tmpId]),
									[action.scenarioAction]
								),
							}
						: workflowRule
				),
				actionErrors: { ...state.actionErrors, [action.scenarioAction.tmpId]: action.data },
			};

		case RoutingWorkFlowScenarioActionConstants.WORKFLOW_SCENARIO_ACTION_UPDATE_SUCCESS:
			return {
				...state,
				scenarios: map(state.scenarios, workflowRule =>
					isRuleIdOrParentId(workflowRule, action.data.routing_rule)
						? {
								...workflowRule,
								workflow_rule_actions: map(workflowRule.workflow_rule_actions, workflowRuleAction =>
									workflowRuleAction.id === action.data.id
										? { ...workflowRuleAction, ...action.data }
										: workflowRuleAction
								),
							}
						: workflowRule
				),
				actionErrors: pickBy(state.actionErrors, (_v, k) => action.data.id !== parseInt(k, 10)),
			};

		case RoutingWorkFlowScenarioCriteriaConstants.WORKFLOW_SCENARIO_SLA_TMP_UPDATE:
			return {
				...state,
				scenarios: map(state.scenarios, workflowRule =>
					isRuleIdOrParentId(workflowRule, action.routingRule)
						? {
								...workflowRule,
								workflow_rule_actions: map(workflowRule.workflow_rule_actions, workflowRuleAction =>
									workflowRuleAction.id === action.actionId
										? {
												...workflowRuleAction,
												action_details: {
													...workflowRuleAction.action_details,
													criteria_details: map(
														workflowRuleAction.action_details.criteria_details,
														criteria => {
															const exist = find(
																action.data,
																item =>
																	(criteria.tmpId && item.tmpId === criteria.tmpId) ||
																	item.id === criteria.id
															);

															return exist || criteria;
														}
													),
												},
												...action.data,
											}
										: workflowRuleAction
								),
							}
						: workflowRule
				),
				actionErrors: pickBy(state.actionErrors, (_v, k) => action.data.id !== parseInt(k, 10)),
			};

		case RoutingWorkFlowScenarioCriteriaConstants.WORKFLOW_SCENARIO_OPP_TMP_UPDATE:
			return {
				...state,
				scenarios: map(state.scenarios, workflowRule =>
					isRuleIdOrParentId(workflowRule, action.routingRule)
						? {
								...workflowRule,
								workflow_rule_actions: map(workflowRule.workflow_rule_actions, workflowRuleAction =>
									workflowRuleAction.id === action.actionId
										? {
												...workflowRuleAction,
												action_details: {
													...workflowRuleAction.action_details,
													related_opp_rules: map(
														workflowRuleAction.action_details.related_opp_rules,
														rule => {
															const exist = find(
																action.data,
																item =>
																	(rule.tmpId && item.tmpId === rule.tmpId) ||
																	item.id === rule.id
															);

															return exist || rule;
														}
													),
												},
												...action.data,
											}
										: workflowRuleAction
								),
							}
						: workflowRule
				),
				actionErrors: pickBy(state.actionErrors, (_v, k) => action.data.id !== parseInt(k, 10)),
			};
		case RoutingWorkFlowScenarioActionConstants.WORKFLOW_SCENARIO_ACTION_UPDATE_FAIL:
			return {
				...state,
				scenarios: map(state.scenarios, workflowRule =>
					isRuleIdOrParentId(workflowRule, action.scenarioAction.routing_rule)
						? {
								...workflowRule,
								workflow_rule_actions: map(workflowRule.workflow_rule_actions, workflowRuleAction =>
									workflowRuleAction.id === action.scenarioAction.id
										? { ...workflowRuleAction, ...action.scenarioAction }
										: workflowRuleAction
								),
							}
						: workflowRule
				),
				actionErrors: { ...state.actionErrors, [action.scenarioAction.id]: action.data },
			};

		case RoutingWorkFlowScenarioActionConstants.WORKFLOW_SCENARIO_ACTION_DELETE_SUCCESS:
			return {
				...state,
				scenarios: map(state.scenarios, workflowRule =>
					isRuleIdOrParentId(workflowRule, action.scenarioAction.routing_rule)
						? {
								...workflowRule,
								workflow_rule_actions: filter(
									workflowRule.workflow_rule_actions,
									workflowRuleAction =>
										(workflowRuleAction.id || workflowRuleAction.tmpId) !==
										(action.scenarioAction.id || action.scenarioAction.tmpId)
								),
							}
						: workflowRule
				),
			};

		case RoutingWorkFlowScenarioActionConstants.WORKFLOW_SCENARIO_ACTION_DELETE_FAIL:
			return state;

		case RoutingWorkFlowScenarioCriteriaConstants.WORKFLOW_SCENARIO_CRITERIA_CREATE_SUCCESS:
			if (action.data.length === 0) {
				return state;
			}

			return {
				...state,
				scenarios: map(state.scenarios, workflowRule => {
					if (isRuleIdOrParentId(workflowRule, action.data[0].routing_rule)) {
						const criteriaTmpIds = map(action.criterias, 'tmpId');
						return {
							...workflowRule,
							workflow_rule_criterias: concat(
								[],
								filter(
									workflowRule.workflow_rule_criterias,
									workflowRuleCriteria => !includes(criteriaTmpIds, workflowRuleCriteria.tmpId)
								),
								action.data
							),
						};
					}

					return workflowRule;
				}),
				criteriaErrors: pickBy(
					state.criteriaErrors,
					(_v, k) => !includes(map(action.criterias, 'tmpId'), parseInt(k, 10))
				),
			};

		case RoutingWorkFlowScenarioCriteriaConstants.WORKFLOW_SCENARIO_CRITERIA_CREATE_FAIL:
			if (action.criterias.length === 0) {
				return state;
			}

			return {
				...state,
				scenarios: map(state.scenarios, workflowRule => {
					if (isRuleIdOrParentId(workflowRule, action.criterias[0].routing_rule)) {
						const criteriaTmpIds = map(action.criterias, 'tmpId');
						return {
							...workflowRule,
							workflow_rule_criterias: concat(
								[],
								filter(
									workflowRule.workflow_rule_criterias,
									workflowRuleCriteria => !includes(criteriaTmpIds, workflowRuleCriteria.tmpId)
								),
								action.criterias
							),
						};
					}

					return workflowRule;
				}),
				criteriaErrors: {
					...state.criteriaErrors,
					...reduce(
						action.criterias,
						(result, criteria, i) => ({ ...result, [criteria.tmpId]: action.data[i] }),
						{}
					),
				},
			};

		case RoutingWorkFlowScenarioCriteriaConstants.WORKFLOW_SCENARIO_CRITERIA_TMP_UPDATE:
			return {
				...state,
				scenarios: map(state.scenarios, workflowRule => {
					if (isRuleIdOrParentId(workflowRule, action.data[0].routing_rule)) {
						return {
							...workflowRule,
							workflow_rule_criterias: map(workflowRule.workflow_rule_criterias, ctr => {
								const exist = find(
									action.data,
									item => (ctr.tmpId && item.tmpId === ctr.tmpId) || item.id === ctr.id
								);

								return exist || ctr;
							}),
						};
					}

					return workflowRule;
				}),
			};

		case RoutingWorkFlowScenarioCriteriaConstants.WORKFLOW_SCENARIO_CRITERIA_UPDATE_SUCCESS:
			if (action.data.length === 0) {
				return state;
			}

			return {
				...state,
				scenarios: map(state.scenarios, workflowRule =>
					isRuleIdOrParentId(workflowRule, action.data[0].routing_rule)
						? {
								...workflowRule,
								workflow_rule_criterias: map(
									workflowRule.workflow_rule_criterias,
									workflowRuleCriteria => {
										const newWorkflowRuleCriteria = find(action.data, [
											'id',
											workflowRuleCriteria.id,
										]);

										return newWorkflowRuleCriteria
											? {
													...workflowRuleCriteria,
													...newWorkflowRuleCriteria,
												}
											: workflowRuleCriteria;
									}
								),
							}
						: workflowRule
				),
				criteriaErrors: pickBy(
					state.criteriaErrors,
					(_v, k) => !includes(map(action.data, 'id'), parseInt(k, 10))
				),
			};

		case RoutingWorkFlowScenarioCriteriaConstants.WORKFLOW_SCENARIO_CRITERIA_UPDATE_FAIL:
			if (action.criterias.length === 0) {
				return state;
			}

			return {
				...state,
				scenarios: map(state.scenarios, workflowRule =>
					isRuleIdOrParentId(workflowRule, action.criterias[0].routing_rule)
						? {
								...workflowRule,
								workflow_rule_criterias: map(
									workflowRule.workflow_rule_criterias,
									workflowRuleCriteria => {
										const newWorkflowRuleCriteria = find(action.criterias, [
											'id',
											workflowRuleCriteria.id,
										]);

										return workflowRuleCriteria
											? {
													...workflowRuleCriteria,
													...newWorkflowRuleCriteria,
												}
											: workflowRuleCriteria;
									}
								),
							}
						: workflowRule
				),
				criteriaErrors: {
					...state.criteriaErrors,
					...reduce(
						action.criterias,
						(result, criteria, i) => ({ ...result, [criteria.id]: action.data[i] }),
						{}
					),
				},
			};

		case RoutingWorkFlowScenarioCriteriaConstants.WORKFLOW_SCENARIO_CRITERIA_DELETE_SUCCESS:
			return {
				...state,
				scenarios: map(state.scenarios, workflowRule =>
					isRuleIdOrParentId(workflowRule, action.criteria.routing_rule)
						? {
								...workflowRule,
								workflow_rule_criterias: filter(
									workflowRule.workflow_rule_criterias,
									workflowRuleCriteria =>
										(workflowRuleCriteria.id || workflowRuleCriteria.tmpId) !==
										(action.criteria.id || action.criteria.tmpId)
								),
							}
						: workflowRule
				),
			};

		case RoutingWorkFlowScenarioActionConstants.WORKFLOW_SCENARIO_ACTION_CREATE_REQUEST:
			return { ...state, isActionCreateRequest: true };

		case RoutingWorkFlowScenarioCriteriaConstants.WORKFLOW_SCENARIO_CRITERIA_DELETE_FAIL:
			return state;

		default:
			return state;
	}
};
