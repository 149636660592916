export const STATUSES = {
	Success: ['PROCESSED'],
	Error: ['ERROR'],
	Skipped: ['SKIPPED'],
	'In processing': ['NOT_PROCESSED', 'MATCHING', 'RESOLUTION', 'PROCESSING'],
};

export const TABLE_COLS = [
	'Full Name',
	'Email',
	'Company',
	'Status',
	'Date/Time',
	'Last Updated',
	'Task',
	'Record ID',
	'Matching Result',
	'Rule Triggered',
	'Ownership',
	'Field Value Change',
];

export const ROUTE_LOGS_FIELDS_EXPORT = [
	'Record Id',
	'External Record Id',
	'First Name',
	'Last Name',
	'Email',
	'Company',
	'Record Status',
	'Date',
	'Time',
	'Last Updated Date',
	'Last Updated Time',
	'Task Name',
	'Matched Object',
	'Workflow Name',
	'Rule Name',
	'Owner Id',
	'Owner Name',
	'Is Queue',
	'Assignment Type',
	'Field Updates',
	'Round Robin Name',
];

export const ROUTE_LOGS_EXPORT_DATE_FORMAT = 'YYYY-MM-DD';
