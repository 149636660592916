import concat from 'lodash-es/concat';
import filter from 'lodash-es/filter';
import find from 'lodash-es/find';
import forEach from 'lodash-es/forEach';
import includes from 'lodash-es/includes';
import invokeMap from 'lodash-es/invokeMap';
import map from 'lodash-es/map';
import pickBy from 'lodash-es/pickBy';
import reduce from 'lodash-es/reduce';
import { LOCATION_CHANGE } from 'redux-first-history';

import { SurvivingFieldValueRulesConstants } from '../Constants';

const initialState = {
	survivingFieldValueRules: [],
	fetching: true,
	errors: {},
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			return initialState;

		case SurvivingFieldValueRulesConstants.LOAD_SURVIVING_FIELD_VALUE_RULES_SUCCESS:
			return { ...state, survivingFieldValueRules: action.data, fetching: false };

		case SurvivingFieldValueRulesConstants.LOAD_SURVIVING_FIELD_VALUE_RULES_FAIL:
			return { ...state, survivingFieldValueRules: [], fetching: false };

		case SurvivingFieldValueRulesConstants.CREATE_SURVIVING_FIELD_VALUE_RULE_SUCCESS: {
			let { survivingFieldValueRules } = state;
			const allSfVrTmpIds = map(action.rules, 'tmpId');

			forEach(action.rules, (rule, index) => {
				survivingFieldValueRules = find(survivingFieldValueRules, ['tmpId', rule.tmpId])
					? map(survivingFieldValueRules, survivingFieldValueRule =>
							survivingFieldValueRule.tmpId === rule.tmpId
								? { ...survivingFieldValueRule, ...action.data[index] }
								: survivingFieldValueRule
						)
					: concat([], survivingFieldValueRules, action.data[index]);
			});

			return {
				...state,
				survivingFieldValueRules,
				errors: pickBy(state.errors, (_v, k) => !includes(allSfVrTmpIds, k)),
			};
		}

		case SurvivingFieldValueRulesConstants.CREATE_SURVIVING_FIELD_VALUE_RULE_FAIL: {
			let { survivingFieldValueRules } = state;

			forEach(action.rules, rule => {
				survivingFieldValueRules = includes(map(survivingFieldValueRules, 'tmpId'), rule.tmpId)
					? map(survivingFieldValueRules, survivingFieldValueRule => {
							const actionRule = find(action.rules, ['tmpId', survivingFieldValueRule.tmpId]);

							return actionRule ? { ...survivingFieldValueRule, ...actionRule } : survivingFieldValueRule;
						})
					: concat([], state.survivingFieldValueRules, rule);
			});

			return {
				...state,
				survivingFieldValueRules,
				errors: {
					...state.errors,
					...reduce(
						action.rules,
						(result, rule, index) => ({
							...result,
							[rule.tmpId]: action.data[index] || {},
						}),
						{}
					),
				},
			};
		}

		case SurvivingFieldValueRulesConstants.PATCH_SURVIVING_FIELD_VALUE_RULE_SUCCESS: {
			const allSfVrTmpIds = map(action.rules, rule => rule.id.toString());

			return {
				...state,
				survivingFieldValueRules: map(state.survivingFieldValueRules, survivingFieldValueRule => {
					const actionRule = find(action.data, ['id', survivingFieldValueRule.id]);

					return actionRule ? { ...survivingFieldValueRule, ...actionRule } : survivingFieldValueRule;
				}),
				errors: pickBy(state.errors, (_v, k) => !includes(allSfVrTmpIds, k)),
			};
		}

		case SurvivingFieldValueRulesConstants.PATCH_SURVIVING_FIELD_VALUE_RULE_FAIL:
			return {
				...state,
				survivingFieldValueRules: map(state.survivingFieldValueRules, survivingFieldValueRule => {
					const actionRule = find(action.rules, ['id', survivingFieldValueRule.id]);

					return actionRule ? { ...survivingFieldValueRule, ...actionRule } : survivingFieldValueRule;
				}),
				errors: {
					...state.errors,
					...reduce(
						action.rules,
						(result, rule, index) => ({ ...result, [rule.id]: action.data[index] || {} }),
						{}
					),
				},
			};

		case SurvivingFieldValueRulesConstants.DELETE_SURVIVING_FIELD_VALUE_RULE_SUCCESS: {
			const allSfVrTmpIds = invokeMap(action.ids, 'toString');

			return {
				...state,
				survivingFieldValueRules: filter(
					state.survivingFieldValueRules,
					survivingFieldValueRule =>
						!includes(action.ids, survivingFieldValueRule.id) &&
						!includes(action.ids, survivingFieldValueRule.tmpId)
				),
				errors: pickBy(state.errors, (_v, k) => !includes(allSfVrTmpIds, k)),
			};
		}

		case SurvivingFieldValueRulesConstants.DELETE_SURVIVING_FIELD_VALUE_RULE_FAIL:
			return state;

		default:
			return state;
	}
};
