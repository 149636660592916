import { createApi } from '@reduxjs/toolkit/dist/query/react';
import baseQueryWithNProgress from 'app/utils/services/baseQueryWithNProgress';

import type {
	CreateVendorKeyPayload,
	UpdateVendorKeyPayload,
	VendorKey,
	VendorKeySimple,
	VendorKeyUsage,
	VerifyVendorKeyPayload,
} from '../types/vendorKeyTypes';

export const vendorKeyApi = createApi({
	reducerPath: 'vendorKeyApi',
	baseQuery: baseQueryWithNProgress,
	tagTypes: ['VendorKeysList'],
	endpoints: builder => ({
		getVendorKeys: builder.query<VendorKey[], void>({
			query: () => '/data_exchange/vendor_keys/',
		}),
		getVendorKeysSimpleList: builder.query<VendorKeySimple[], void>({
			query: () => '/data_exchange/vendor_keys/simple/',
			providesTags: ['VendorKeysList'],
		}),
		verifyVendorKey: builder.mutation<string, VerifyVendorKeyPayload>({
			query: body => ({
				url: '/data_exchange/vendor_keys/verify_key/',
				method: 'POST',
				body,
			}),
		}),
		createVendorKey: builder.mutation<VendorKey, CreateVendorKeyPayload>({
			query: body => ({
				url: `/data_exchange/vendor_keys/`,
				method: 'POST',
				body,
			}),
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(
						vendorKeyApi.util.updateQueryData('getVendorKeys', undefined, draft => {
							draft.push(data);
						})
					);
				} catch {
					/* empty */
				}
			},
			invalidatesTags: ['VendorKeysList'],
		}),
		updateVendorKey: builder.mutation<VendorKey, UpdateVendorKeyPayload>({
			query: ({ vendorId, body }) => ({
				url: `/data_exchange/vendor_keys/${vendorId}/`,
				method: 'PATCH',
				body,
			}),
			async onQueryStarted({ vendorId }, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(
						vendorKeyApi.util.updateQueryData('getVendorKeys', undefined, draft =>
							draft.map(vendor => (vendor.id === vendorId ? { ...vendor, ...data } : vendor))
						)
					);
				} catch {
					/* empty */
				}
			},
			invalidatesTags: ['VendorKeysList'],
		}),
		removeVendorKey: builder.mutation<void, number>({
			query: id => ({
				url: `/data_exchange/vendor_keys/${id}/`,
				method: 'DELETE',
			}),
			onQueryStarted(id, { dispatch, queryFulfilled }) {
				const patchResult = dispatch(
					vendorKeyApi.util.updateQueryData('getVendorKeys', undefined, draft =>
						draft.filter(vendor => vendor.id !== id)
					)
				);
				queryFulfilled.catch(patchResult.undo);
			},
			invalidatesTags: ['VendorKeysList'],
		}),
		getVendorKeyUsage: builder.query<VendorKeyUsage[], number>({
			query: id => `/data_exchange/vendor_keys/${id}/usage/`,
		}),
	}),
});

export const {
	useGetVendorKeysQuery,
	useGetVendorKeysSimpleListQuery,
	useVerifyVendorKeyMutation,
	useCreateVendorKeyMutation,
	useUpdateVendorKeyMutation,
	useRemoveVendorKeyMutation,
	useGetVendorKeyUsageQuery,
} = vendorKeyApi;
