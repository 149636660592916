import { DiscoveryConstants } from 'app/dop/Constants';
import find from 'lodash-es/find';
import includes from 'lodash-es/includes';
import map from 'lodash-es/map';
import pickBy from 'lodash-es/pickBy';
import reject from 'lodash-es/reject';

import { createFiltersBooleanLogic } from '../components/tasks/discovery/searches/helpers';

const initialState = {
	filters: [],
	inValidFilters: [],
	errors: {},
	fetchingFilters: false,
	filter: null,
	enableNestedBooleanLogic: false,
	nestedBooleanLogic: null,
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export default (state = initialState, action = {}) => {
	switch (action.type) {
		case DiscoveryConstants.DISCOVERY_GET_FILTERS:
			return { ...state, fetchingFilters: true, filters: [], inValidFilters: [], errors: {} };

		case DiscoveryConstants.DISCOVERY_GET_FILTERS_SUCCESS:
			return { ...state, fetchingFilters: false, filters: action.data, inValidFilters: [], errors: {} };

		case DiscoveryConstants.DISCOVERY_GET_FILTERS_FAIL:
			return { ...state, fetchingFilters: false, filters: [], inValidFilters: [], errors: {} };

		case DiscoveryConstants.DISCOVERY_GET_FILTER_SUCCESS:
			return { ...state, fetchingFilters: false, filter: action.data };

		case DiscoveryConstants.DISCOVERY_GET_FILTER_FAIL:
			return { ...state, fetchingFilters: false, filter: null };

		case DiscoveryConstants.DISCOVERY_TMP_FILTER_CREATE:
			return {
				...state,
				filters: [...state.filters, action.data],
				inValidFilters: [...state.inValidFilters, action.data.tmpId],
			};

		case DiscoveryConstants.DISCOVERY_TMP_VALID_FILTER_CREATE:
			const filtersAfterCreate = [...state.filters, action.data];
			const nestedBooleanLogicAfterCreate = action.refreshNestedBooleanLogic
				? createFiltersBooleanLogic(filtersAfterCreate, true)
				: state.nestedBooleanLogic;

			return {
				...state,
				filters: filtersAfterCreate,
				nestedBooleanLogic: nestedBooleanLogicAfterCreate,
			};

		case DiscoveryConstants.DISCOVERY_FILTER_CREATE:
			return {
				...state,
				filters: map(state.filters, f => (f.tmpId && f.tmpId === action.tmpId ? { ...f, locked: true } : f)),
			};

		case DiscoveryConstants.DISCOVERY_FILTER_CREATE_SUCCESS:
			return {
				...state,
				filters: [...reject(state.filters, ['tmpId', action.tmpId]), action.data],
				inValidFilters: reject(state.inValidFilters, f => f === action.tmpId),
				errors: pickBy(state.errors, (_v, k) => k !== action.tmpId),
			};

		case DiscoveryConstants.DISCOVERY_FILTER_CREATE_FAIL:
			return {
				...state,
				filters: map(state.filters, f => (f.tmpId && f.tmpId === action.tmpId ? { ...f, locked: false } : f)),
				errors: { ...state.errors, [action.tmpId]: action.error },
			};

		case DiscoveryConstants.DISCOVERY_TMP_FILTER_UPDATE:
			const updatedFilterId = action.data.id || action.data.tmpId;
			const wasInvalid = includes(state.inValidFilters, updatedFilterId);

			return {
				...state,
				filters: map(state.filters, f => {
					if (includes([f.id, f.tmpId], updatedFilterId)) {
						return action.data;
					}
					return f;
				}),
				inValidFilters: wasInvalid ? state.inValidFilters : [...state.inValidFilters, updatedFilterId],
				errors: pickBy(state.errors, (_v, k) => k !== updatedFilterId),
			};

		case DiscoveryConstants.DISCOVERY_TMP_VALID_FILTER_UPDATE:
			const filtersAfterUpdate = map(state.filters, f => {
				if (f.tmpId === action.data.tmpId) {
					return action.data;
				}
				return f;
			});
			const nestedBooleanLogicAfterUpdate = action.refreshNestedBooleanLogic
				? createFiltersBooleanLogic(filtersAfterUpdate, true)
				: state.nestedBooleanLogic;

			return {
				...state,
				filters: filtersAfterUpdate,
				inValidFilters: reject(state.inValidFilters, f => f === action.data.tmpId),
				nestedBooleanLogic: nestedBooleanLogicAfterUpdate,
			};

		case DiscoveryConstants.DISCOVERY_FILTER_UPDATE_SUCCESS:
			return {
				...state,
				filters: map(state.filters, f => {
					if (action.data.id === f.id) {
						return action.data;
					}
					return f;
				}),
				inValidFilters: reject(state.inValidFilters, f => f === action.data.id),
				errors: pickBy(state.errors, (_v, k) => k !== action.data.id),
			};

		case DiscoveryConstants.DISCOVERY_FILTER_UPDATE_FAIL:
			return { ...state, errors: { ...state.errors, [action.id]: action.error } };

		case DiscoveryConstants.DISCOVERY_TMP_FILTERS_UPDATE:
			return {
				...state,
				filters: map(state.filters, filter => {
					const exist = find(action.data, ['tmpId', filter.tmpId]);
					if (exist) {
						return exist;
					}
					return filter;
				}),
			};

		case DiscoveryConstants.DISCOVERY_FILTERS_ORDER_UPDATE:
			return {
				...state,
				filters: map(state.filters, f => {
					const actionFilter = find(action.data, ['id', f.id]);
					return actionFilter ? { ...f, ...actionFilter } : f;
				}),
			};

		case DiscoveryConstants.DISCOVERY_FILTERS_UPDATE_SUCCESS:
			return {
				...state,
				filters: map(state.filters, filter => {
					const exist = find(action.data, ['id', filter.id]);
					if (exist) {
						return exist;
					}
					return filter;
				}),
			};

		case DiscoveryConstants.DISCOVERY_FILTERS_UPDATE_FAIL:
			return { ...state };

		case DiscoveryConstants.DISCOVERY_TMP_FILTER_DELETE:
			const filtersAfterDelete = reject(state.filters, ['tmpId', action.filterTmpId]);
			const nestedBooleanLogicAfterDelete = action.refreshNestedBooleanLogic
				? createFiltersBooleanLogic(filtersAfterDelete, true)
				: state.nestedBooleanLogic;

			return {
				...state,
				filters: filtersAfterDelete,
				inValidFilters: reject(state.inValidFilters, f => f === action.filterTmpId),
				nestedBooleanLogic: nestedBooleanLogicAfterDelete,
				errors: pickBy(state.errors, (_v, k) => k !== action.filterTmpId),
			};

		case DiscoveryConstants.DISCOVERY_FILTER_DELETE_SUCCESS:
			return {
				...state,
				filters: reject(state.filters, ['id', action.filterId]),
				inValidFilters: reject(state.inValidFilters, f => f === action.filterId),
				errors: pickBy(state.errors, (_v, k) => k !== action.filterId),
			};

		case DiscoveryConstants.DISCOVERY_FILTER_DELETE_FAIL:
			return { ...state };

		case DiscoveryConstants.DISCOVERY_ALL_FILTERS_DELETE_SUCCESS:
			return { ...state, filters: [], errors: {} };

		case DiscoveryConstants.DISCOVERY_ALL_FILTERS_DELETE_FAIL:
			return { ...state };

		case DiscoveryConstants.DISCOVERY_SET_PARENTS_FILTERS:
			return { ...state, filters: action.data, inValidFilters: [], errors: {} };

		case DiscoveryConstants.DISCOVERY_UPDATE_NESTED_BOOLEAN_LOGIC:
			return { ...state, enableNestedBooleanLogic: action.enable, nestedBooleanLogic: action.booleanLogic };

		case DiscoveryConstants.DISCOVERY_SET_NESTED_BOOLEAN_LOGIC:
			return { ...state, nestedBooleanLogic: action.booleanLogic };

		default:
			return state;
	}
};
