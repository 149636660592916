/* eslint-disable sonarjs/max-switch-cases */
import find from 'lodash-es/find';
import forEach from 'lodash-es/forEach';
import map from 'lodash-es/map';
import { LOCATION_CHANGE } from 'redux-first-history';

import DiscoverySearchTypes from '../components/tasks/discovery/searches/DiscoverySearchTypes';
import { DiscoveryConstants } from '../Constants';

const initialState = {
	fetchingMetadata: true,
	metadata: null,
	fetching: true,
	results: {},
	fetchingVendorData: true,
	vendorData: null,
	rebuilding: false,
	recent_filters: [],
	locked: false,
	fetchingSearchTypes: true,
	searchTypes: [],
	fetchingVendorKeys: false,
	vendorKeys: [],
	fetchingListImportTasks: false,
	listImportTasks: [],
	linkedTasks: [],
	error: null,
	fetchingTotalSuppressed: false,
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			return initialState;

		case DiscoveryConstants.DISCOVERY_GET_METADATA_SUCCESS:
			return { ...state, fetchingMetadata: false, metadata: action.data };

		case DiscoveryConstants.DISCOVERY_GET_METADATA_FAIL:
			return { ...state, fetchingMetadata: false, metadata: null };

		case DiscoveryConstants.DISCOVERY_SEARCH_RESULT:
			return { ...state, fetching: true };

		case DiscoveryConstants.DISCOVERY_SEARCH_RESULT_SUCCESS:
			return { ...state, fetching: false, results: { ...action.data } };

		case DiscoveryConstants.DISCOVERY_SEARCH_RESULT_FAIL:
			return { ...state, fetching: false, results: {}, error: action.data.error };

		case DiscoveryConstants.DISCOVERY_GET_LIST_IMPORT_TASKS:
			return { ...state, fetchingListImportTasks: true };

		case DiscoveryConstants.DISCOVERY_GET_LIST_IMPORT_TASKS_SUCCESS:
			return { ...state, fetchingListImportTasks: false, listImportTasks: action.data };

		case DiscoveryConstants.DISCOVERY_GET_LIST_IMPORT_TASKS_FAIL:
			return { ...state, fetchingListImportTasks: false, listImportTasks: [] };

		case DiscoveryConstants.DISCOVERY_EXPORT_TO_LIST_IMPORT:
			return { ...state, locked: true };

		case DiscoveryConstants.DISCOVERY_EXPORT_TO_LIST_IMPORT_SUCCESS:
		case DiscoveryConstants.DISCOVERY_EXPORT_TO_LIST_IMPORT_FAIL:
			return { ...state, locked: false };

		case DiscoveryConstants.DISCOVERY_GET_VENDOR_DATA:
			return { ...state, fetchingVendorData: true };

		case DiscoveryConstants.DISCOVERY_GET_VENDOR_DATA_SUCCESS:
			return { ...state, fetchingVendorData: false, vendorData: action.data };

		case DiscoveryConstants.DISCOVERY_GET_VENDOR_DATA_FAIL:
			return { ...state, fetchingVendorData: false, vendorData: null };

		case DiscoveryConstants.DISCOVERY_GET_SEARCH_TYPE_LIST_SUCCESS:
			return { ...state, fetchingSearchTypes: false, searchTypes: action.data };

		case DiscoveryConstants.DISCOVERY_GET_SEARCH_TYPE_LIST_FAIL:
			return { ...state, fetchingSearchTypes: false, searchTypes: null };

		case DiscoveryConstants.DISCOVERY_GET_VENDOR_KEYS:
			return { ...state, fetchingVendorKeys: true, vendorKeys: [] };

		case DiscoveryConstants.DISCOVERY_GET_VENDOR_KEYS_SUCCESS:
			return { ...state, fetchingVendorKeys: false, vendorKeys: action.data };

		case DiscoveryConstants.DISCOVERY_GET_VENDOR_KEYS_FAIL:
			return { ...state, fetchingVendorKeys: false, vendorKeys: [] };

		case DiscoveryConstants.DISCOVERY_DATA_REBUILD:
			return { ...state, rebuilding: true };

		case DiscoveryConstants.DISCOVERY_DATA_REBUILD_SUCCESS: {
			const data = action.data.result[action.searchType];
			const idFieldName = action.searchType === DiscoverySearchTypes.companies ? 'cid' : 'pid';
			forEach(data, element => {
				const el = find(state.results.hits, ['_id', element[idFieldName]]);

				if (!el) {
					return;
				}

				forEach(element.data, (value, key) => {
					if (!el._source[key]) {
						return;
					}

					el._source[key] = value;
				});

				el._source._updated_on = element.updated_on;
				el._source._history = element.history;
				el._source._vendors = element.vendors;
			});
			return { ...state, rebuilding: false };
		}

		case DiscoveryConstants.DISCOVERY_DATA_REBUILD_FAIL:
			return { ...state, rebuilding: false };

		case DiscoveryConstants.DISCOVERY_GET_RECENT_FILTERS_SUCCESS:
			return { ...state, recent_filters: action.data };

		case DiscoveryConstants.DISCOVERY_ENRICH_RECORDS_SUCCESS:
			const newResults = { ...state.results };
			const idField =
				action.searchType === DiscoverySearchTypes.contacts_zoominfo
					? 'ZoomInfoContactRecordID'
					: 'ZoomInfoCompanyRecordID';
			newResults.hits = map(newResults.hits, elem => {
				const enrichmentFieldsForCurrentElem = find(action.data, res => Number(res?.[idField]) === elem._id);
				return enrichmentFieldsForCurrentElem
					? { ...elem, _source: { ...elem._source, ...enrichmentFieldsForCurrentElem } }
					: elem;
			});

			return { ...state, results: newResults };

		case DiscoveryConstants.DISCOVERY_DATA_SEARCH_RESULTS_REBUILD_SUCCESS:
		case DiscoveryConstants.DISCOVERY_DATA_SEARCH_RESULTS_REBUILD_FAIL:
		case DiscoveryConstants.DISCOVERY_GET_RECENT_FILTERS_FAIL:
			return state;

		case DiscoveryConstants.DISCOVERY_GET_LINKED_TASKS_SUCCESS:
			return { ...state, linkedTasks: action.data };

		case DiscoveryConstants.DISCOVERY_GET_LINKED_TASKS_FAIL:
			return { ...state, linkedTasks: [] };

		case DiscoveryConstants.DISCOVERY_GET_TOTAL_SUPPRESSED_SUCCESS:
			return { ...state, fetchingTotalSuppressed: true };

		case DiscoveryConstants.DISCOVERY_UPDATE_FETCHING_TOTAL_SUPPRESSED:
			return { ...state, fetchingTotalSuppressed: action.data };

		case DiscoveryConstants.DISCOVERY_GET_TOTAL_SUPPRESSED_FAIL:
		case DiscoveryConstants.DISCOVERY_CANCEL_GET_TOTAL_SUPPRESSED_SUCCESS:
		case DiscoveryConstants.DISCOVERY_CANCEL_GET_TOTAL_SUPPRESSED_FAIL:
			return { ...state, fetchingTotalSuppressed: false };

		default:
			return state;
	}
};
