import concat from 'lodash-es/concat';
import filter from 'lodash-es/filter';
import find from 'lodash-es/find';
import forEach from 'lodash-es/forEach';
import includes from 'lodash-es/includes';
import invokeMap from 'lodash-es/invokeMap';
import map from 'lodash-es/map';
import pickBy from 'lodash-es/pickBy';
import reduce from 'lodash-es/reduce';
import { LOCATION_CHANGE } from 'redux-first-history';

import { GroupFiltersConstants } from '../Constants';

const initialState = {
	groupFilters: [],
	fetching: true,
	errors: {},
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			return initialState;

		case GroupFiltersConstants.LOAD_GROUP_FILTERS_SUCCESS:
			return { ...state, groupFilters: action.data, fetching: false };

		case GroupFiltersConstants.LOAD_GROUP_FILTERS_FAIL:
			return { ...state, groupFilters: [], fetching: false };

		case GroupFiltersConstants.CREATE_GROUP_FILTER_SUCCESS: {
			let { groupFilters } = state;

			forEach(action.filters, (actionGroupFIlter, index) => {
				groupFilters = find(groupFilters, ['tmpId', actionGroupFIlter.tmpId])
					? map(groupFilters, groupFilter =>
							groupFilter.tmpId === actionGroupFIlter.tmpId
								? { ...groupFilter, ...action.data[index] }
								: groupFilter
						)
					: concat([], groupFilters, action.data[index]);
			});

			return {
				...state,
				groupFilters,
				errors: pickBy(state.errors, (_v, k) => !includes(map(action.filters, 'tmpId'), k)),
			};
		}

		case GroupFiltersConstants.CREATE_GROUP_FILTER_FAIL: {
			let { groupFilters } = state;

			forEach(action.filters, actionGroupFIlter => {
				groupFilters = includes(map(groupFilters, 'tmpId'), actionGroupFIlter.tmpId)
					? map(groupFilters, groupFilter => {
							const actionFilter = find(action.filters, ['tmpId', groupFilter.tmpId]);

							return actionFilter ? { ...groupFilter, ...actionFilter } : groupFilter;
						})
					: concat([], state.groupFilters, actionGroupFIlter);
			});

			return {
				...state,
				groupFilters,
				errors: {
					...state.errors,
					...reduce(
						action.filters,
						(result, groupFIlter, index) => ({ ...result, [groupFIlter.tmpId]: action.data[index] || {} }),
						{}
					),
				},
			};
		}

		case GroupFiltersConstants.PATCH_GROUP_FILTER_SUCCESS: {
			const allGroupFiltersIds = map(action.filters, groupFilter => groupFilter.id.toString());

			return {
				...state,
				groupFilters: map(state.groupFilters, groupFilter => {
					const actionFilter = find(action.data, ['id', groupFilter.id]);

					return actionFilter ? { ...groupFilter, ...actionFilter } : groupFilter;
				}),
				errors: pickBy(state.errors, (_v, k) => !includes(allGroupFiltersIds, k)),
			};
		}

		case GroupFiltersConstants.PATCH_GROUP_FILTER_FAIL:
			return {
				...state,
				groupFilters: map(state.groupFilters, groupFilter => {
					const actionFilter = find(action.filters, ['id', groupFilter.id]);

					return actionFilter ? { ...groupFilter, ...actionFilter } : groupFilter;
				}),
				errors: {
					...state.errors,
					...reduce(
						action.filters,
						(result, rule, index) => ({ ...result, [rule.id]: action.data[index] || {} }),
						{}
					),
				},
			};

		case GroupFiltersConstants.DELETE_GROUP_FILTER_SUCCESS: {
			const allGroupFiltersIds = invokeMap(action.ids, 'toString');

			return {
				...state,
				groupFilters: filter(
					state.groupFilters,
					groupFilter => !includes(action.ids, groupFilter.id) && !includes(action.ids, groupFilter.tmpId)
				),
				errors: pickBy(state.errors, (_v, k) => !includes(allGroupFiltersIds, k)),
			};
		}

		case GroupFiltersConstants.DELETE_GROUP_FILTER_FAIL:
			return state;

		default:
			return state;
	}
};
